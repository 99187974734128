import React, { useEffect, useState } from 'react';
import { FirebaseApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import "../css/sentimentDataDisplay.css"
import { useParams } from 'react-router-dom';

interface Props {
  firebaseApp: FirebaseApp;
}

interface SentimentAnalysisData {
  title: string;
  sentiment: number;
  summary: string;
  why_grade: string;
  positive_words: string[];
  negative_words: string[];
  neutral_words: string[];
  labels: string[];
  category: string[];
  url: string;
}

const SentimentAnalysisData: React.FC<Props> = ({ firebaseApp }) => {
  const [data, setData] = useState<SentimentAnalysisData | null>(null);
  const { id } = useParams<{ id: string }>(); // Get the ID from the URL
  const { dateday } = useParams<{ dateday: string }>(); // Get the ID from the URL

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore(firebaseApp);

      const sentimentDocRef = doc(db, `sentiment-analysis/${dateday}/articles/${id}`);
      const sentimentDocSnap = await getDoc(sentimentDocRef);

      if (sentimentDocSnap.exists()) {
        setData(sentimentDocSnap.data() as SentimentAnalysisData);
      } else {
        console.log('No document found!');
      }
    };

    fetchData();
  }, [firebaseApp, id]);

  return (
    <div className="data-display">
      {data ? (
        <div className="content">
          <h1 className="title">{data.title}</h1>
          
          <div className="sentiment-container">
            <label className="sentiment-label">Sentiment Score:</label>
            <input 
              type="range" 
              min="0" 
              max="100" 
              value={data.sentiment} 
              className="sentiment-slider" 
              readOnly 
              style={{ background: `linear-gradient(90deg, red 0%, yellow ${data.sentiment}%, green 100%)` }}
            />
          </div>
          <a id="indicator" href={'/sentiment-analysis/day/'+dateday}><button>Check Crypto Sentiment Analysis Indicator</button></a>
          <p className="summary">{data.summary}</p>
          <p className="why-grade">{data.why_grade}</p>
          
          <div className="word-list">
            <div>
              <label className="label">Positive Words:</label>
              <ul className="word-list-ul">
                {data.positive_words.map((word, index) => (
                  <li key={index}>{word}</li>
                ))}
              </ul>
            </div>
            <div>
              <label className="label">Negative Words:</label>
              <ul className="word-list-ul">
                {data.negative_words.map((word, index) => (
                  <li key={index}>{word}</li>
                ))}
              </ul>
            </div>
            <div>
              <label className="label">Neutral Words:</label>
              <ul className="word-list-ul">
                {data.neutral_words.map((word, index) => (
                  <li key={index}>{word}</li>
                ))}
              </ul>
            </div>
          </div>
          
          <div className="category-labels">
            <p><strong>Category:</strong> {data.category.join(', ')}</p>
            <p><strong>Labels:</strong> {data.labels.join(', ')}</p>
          </div>
          
          <a className="article-link" href={data.url} target="_blank" rel="noopener noreferrer">
            Read the full article
          </a>
        </div>
      ) : (
        <p className="loading-text">Loading data...</p>
      )}
    </div>
  );
};

export default SentimentAnalysisData;
