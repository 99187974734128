import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc, DocumentData } from 'firebase/firestore';
import 'firebase/firestore';
import '../css/concepts.css';

interface ConceptsProps {
  firebaseApp: any;
}

interface Concept {
  author: string;
  content: string;
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
  title: string;
}

const Concepts: React.FC<ConceptsProps> = ({ firebaseApp }) => {
  const { slug } = useParams<{ slug: string }>();
  const [concept, setConcept] = useState<Concept | null>(null);

  useEffect(() => {
    const fetchConcept = async () => {
      if (slug) {
        const db = getFirestore(firebaseApp);
        const docRef = doc(db, 'concepts', slug);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setConcept(docSnap.data() as Concept);
        } else {
          console.log('No such document!');
        }
      }
    };

    fetchConcept();
  }, [firebaseApp, slug]);

  if (!concept) {
    return <div>Loading...</div>;
  }

  return (
    <div className="concept-container">
      <h1 className="concept-title">{concept.title}</h1>
      <p className="concept-author">By {concept.author}</p>
      <div
        className="concept-content"
        dangerouslySetInnerHTML={{ __html: concept.content }}
      />
      <p className="concept-date">
        {new Date(concept.createdAt.seconds * 1000).toLocaleDateString()}
      </p>
    </div>
  );
};

export default Concepts;
