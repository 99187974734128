// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAzMfOSZp0sSeaxkRE16jzusaFRcvOYCXc",
  authDomain: "ascendancesounds.firebaseapp.com",
  projectId: "ascendancesounds",
  storageBucket: "ascendancesounds.appspot.com",
  messagingSenderId: "308601375611",
  appId: "1:308601375611:web:7daece6bdf0f40331d8bde",
  measurementId: "G-GBNQ8C5VFM"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, 'us-central1'); // Update region if necessary

export { functions };