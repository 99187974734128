import React, { useEffect, useState } from 'react';
import { FirebaseApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import "../css/sentimentAnalysis.css";
import SentimentNewsFeed from './SentimentNewsFeed';
import { useParams } from 'react-router-dom';

interface Props {
  firebaseApp: FirebaseApp;
}

interface ArticleData {
  sentiment: number;
  positive_words: string[];
  negative_words: string[];
  neutral_words: string[];
}

const SentimentAnalysis: React.FC<Props> = ({ firebaseApp }) => {
  const [averageSentiment, setAverageSentiment] = useState<number | null>(null);
  const { dateday } = useParams<{ dateday: string }>(); // Get the ID from the URL

  const [topWords, setTopWords] = useState<{ positive: string[], negative: string[], neutral: string[] }>({
    positive: [],
    negative: [],
    neutral: [],
  });
  const [summary, setSummary] = useState<string | null>(null); // New state for summary


  useEffect(() => {
    const fetchSentimentData = async () => {
      const db = getFirestore(firebaseApp);
      const sentimentCollectionRef = collection(db, 'sentiment-analysis/'+dateday+'/articles');
      const sentimentSnapshot = await getDocs(sentimentCollectionRef);

      let totalSentiment = 0;
      let positiveWordCounts: { [key: string]: number } = {};
      let negativeWordCounts: { [key: string]: number } = {};
      let neutralWordCounts: { [key: string]: number } = {};
      let docCount = 0;

      sentimentSnapshot.forEach((doc) => {
        const data = doc.data() as ArticleData;
        totalSentiment += data.sentiment;
        docCount++;

        data.positive_words.forEach(word => {
          positiveWordCounts[word] = (positiveWordCounts[word] || 0) + 1;
        });

        data.negative_words.forEach(word => {
          negativeWordCounts[word] = (negativeWordCounts[word] || 0) + 1;
        });

        data.neutral_words.forEach(word => {
          neutralWordCounts[word] = (neutralWordCounts[word] || 0) + 1;
        });
      });

      const averageSentiment = totalSentiment / docCount;
      setAverageSentiment(averageSentiment);

      const topWords = {
        positive: getTopWords(positiveWordCounts),
        negative: getTopWords(negativeWordCounts),
        neutral: getTopWords(neutralWordCounts),
      };

      setTopWords(topWords);
    };
    const fetchSummary = async () => {
        const db = getFirestore(firebaseApp);
        console.log(dateday)
        const summaryDocRef = doc(db, `sentiment-analysis/${dateday!}`);
        const summaryDoc = await getDoc(summaryDocRef);
  
        if (summaryDoc.exists()) {
          const summaryData = summaryDoc.data();
          setSummary(summaryData?.summary || 'No summary available for this date.');
        } else {
          setSummary('No summary available for this date.');
        }
      };
  
      fetchSentimentData();
      fetchSummary();
  

    fetchSentimentData();
  }, [firebaseApp]);

  const getTopWords = (wordCounts: { [key: string]: number }) => {
    return Object.entries(wordCounts)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 5)
      .map(([word]) => word);
  };

  return (
    <div className="sentiment-analysis">
      <h1 className="title">Sentiment Analysis for {dateday}</h1>

      {averageSentiment !== null ? (
        <>
          <div className="sentiment-bar-container">
         
            <div className="sentiment-slider-container sentiment-bar">
              <input
                type="range"
                min="0"
                max="100"
                value={averageSentiment}
                className="sentiment-slider"
                readOnly
              />
            </div>


          </div>
          <p className="sentiment-score">Average Sentiment Score: {averageSentiment.toFixed(2)}</p>

          <div className="top-words">
            <div className="top-words-section">
              <label className="label">Top Positive Words:</label>
              <ul>
                {topWords.positive.map((word, index) => (
                  <li key={index}>{word}</li>
                ))}
              </ul>
            </div>
            <div className="top-words-section">
              <label className="label">Top Negative Words:</label>
              <ul>
                {topWords.negative.map((word, index) => (
                  <li key={index}>{word}</li>
                ))}
              </ul>
            </div>
            <div className="top-words-section">
              <label className="label">Top Neutral Words:</label>
              <ul>
                {topWords.neutral.map((word, index) => (
                  <li key={index}>{word}</li>
                ))}
              </ul>
            </div>
          </div>
          <div style={{padding:"20px"}}
            dangerouslySetInnerHTML={{
              __html: `
                <div className="summary-container">
                  <h2 className="summary-title">Summary</h2>
                  <div className="summary-content">
                    ${summary || ''}
                  </div>
                </div>
              `,
            }}
          />

          <SentimentNewsFeed firebaseApp={firebaseApp} dateday={dateday!}></SentimentNewsFeed>
        </>
      ) : (
        <p className="loading-text">Loading sentiment data...</p>
      )}
    </div>
  );
};

export default SentimentAnalysis;
