import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { FirebaseApp } from 'firebase/app';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import slugify from 'slugify';

interface ArticleEditorProps {
  firebaseApp: FirebaseApp;
}

const ArticleConceptEditor: React.FC<ArticleEditorProps> = ({ firebaseApp }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [user] = useAuthState(getAuth(firebaseApp));
  const firestore = getFirestore(firebaseApp);

  const handleEditorChange = (content: string) => {
    setContent(content);
  };

  const handleSave = async () => {
    if (!title || !content) {
      alert('Title and content are required');
      return;
    }

    const slug = slugify(title, { lower: true });

    try {
      await setDoc(doc(firestore, 'concepts', slug), {
        title,
        content,
        author: "Arthur" || 'Arthur',
        createdAt: new Date()
      });
      alert('Article saved successfully!');
    } catch (error) {
      console.error('Error saving article: ', error);
      alert('Error saving article');
    }
  };

  return (
    <div>
      <h1>Edit Article</h1>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Article Title"
        style={{ width: '100%', padding: '10px', marginBottom: '10px', fontSize: '16px' }}
      />
      <Editor
        apiKey="kaulizd7eylxst9mi2uitk51uxiiqt4iqwzuze97jnhs4pad" // Replace with your TinyMCE API key
        value={content}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'code'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help | code'
        }}
        onEditorChange={handleEditorChange}
      />
      <button onClick={handleSave} style={{ marginTop: '10px', padding: '10px 20px', fontSize: '16px' }}>
        Save
      </button>
    </div>
  );
};

export default ArticleConceptEditor;
