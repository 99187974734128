import React from 'react';
import '../css/WhitePaper.css';

interface WhitePaperProps {
}

const WhitePaper: React.FC<WhitePaperProps> = ({  }) => {
    let content: string = `<h3>Quantum Compass Learn to Earn Academy - White Paper Summary</h3>
<p><strong>Overview:</strong> Quantum Compass is a revolutionary Learn to Earn platform where users can earn Quantum Compass Tokens (QuaC) by engaging in educational activities. The platform offers a unique blend of science, art, and technology lessons, fostering a community where knowledge is rewarded.</p>
<p><strong>Core Principles:</strong></p>
<ol>
<li>
<p><strong>Accessibility (Learn for Free)</strong>: Education should be free and available to everyone. Quantum Compass ensures that high-quality content is accessible to users globally, breaking down barriers to learning.</p>
</li>
<li>
<p><strong>Engagement</strong>: Learning is interactive and rewarding. Users earn QCT by taking lessons, participating in quizzes, and engaging in discussions, promoting active learning.</p>
</li>
<li>
<p><strong>Community-Driven</strong>: Knowledge is shared and expanded through community interaction. Users can contribute by teaching, commenting, and creating content, earning tokens for their contributions.</p>
</li>
<li>
<p><strong>Interdisciplinary Focus</strong>: The platform integrates science, art, and technology, encouraging users to explore diverse fields and develop a well-rounded knowledge base.</p>
</li>
<li>
<p><strong>Community Empowerment</strong>: Quantum Compass is built on a sustainable token economy, where the value of learning is directly tied to the growth of the platform and its community. Community growth hacking. Community empowerment focused.</p>
</li>
</ol>
<p>Fair play, fair launch.</p>`
    return (
        <div className="white-paper-container">
            <div className="white-paper-content" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
}

export default WhitePaper;