import React, { useEffect, useState } from 'react';
import "../css/contributionCards.css";

interface Contribution {
  id: string;
  jsonUrl: string;
  category: string;
}

interface ContributionCardsProps {
  contributions: Contribution[] | undefined;
}
interface BookContribution {
    contributionType: "book"; // Type of contribution
    uid: string; // User ID
    is_doi: boolean; // Whether it has a DOI
    publisher: string; // Publisher of the book
    title_of_the_container: string; // Title of the container (if applicable)
    text_to_contribute: string; // Contribution text or content
    slug: string; // Unique slug for the contribution
    isBookPrint: boolean; // Whether the book is printed
    date: string; // Date of publication
    edition: string; // Edition of the book (if applicable)
    editors: string[]; // Editors (if any)
    authors: string[]; // Authors of the book
    category: string; // Category of the contribution
    title: string; // Title of the book
    approved: boolean; // Approval status of the contribution
    doi_or_url: string; // DOI or URL (if applicable)
    urlOrPrint: string; // URL or print information (if applicable)
  }
  

  interface ContributionNewsPaper {
    contributionType: 'newspaper_article';
    uid: string;
    is_doi: boolean;
    publisher: string;
    title_of_the_container: string;
    text_to_contribute: string;
    slug: string;
    isBookPrint: boolean;
    date: string; // "YYYY, MMMM, DD" format
    edition: string;
    editors: string[];
    authors: string[];
    category: string;
    title: string;
    approved: boolean;
    doi_or_url: string;
    urlOrPrint: string;
    pages: string;
  }
  

const ContributionCards: React.FC<ContributionCardsProps> = ({ contributions }) => {
  const [contributionData, setContributionData] = useState<any[]>([]);

  useEffect(() => {
    const fetchContributions = async () => {
      if (!Array.isArray(contributions)) {
        console.error("Contributions prop is not an array.");
        return;
      }

      const dataPromises = contributions.map(async (contribution) => {
        try {
          const response = await fetch(contribution.jsonUrl);
          const data = await response.json();
          return { ...contribution, data };
        } catch (error) {
          console.error(`Error fetching data for ${contribution.id}: `, error);
          return { ...contribution, data: null };
        }
      });

      const results = await Promise.all(dataPromises);
      setContributionData(results);
    };

    fetchContributions();
  }, [contributions]);

  return (
    <div className="cards-container">
      {contributionData.length > 0 ? (
        contributionData.map((contribution, index) => (
          <div key={index} className="card" id={`contribution-${index}`}>
            <h2 className="category-title">{contribution.category}</h2>
           
            <div className='json-content'>
            {contribution.data ? (
              <div >
                    <p className='contribution_title'>
                     {contribution.data.title}, from a 
                     {contribution.data.contributionType==="book" &&("(book) 📖")}
                     {contribution.data.contributionType==="webpage" &&("(webpage) 🌐")}

                     {contribution.data.contributionType==="newspaper_article" &&("(newaspaper article) 📰")}</p>
                <p style={{color:"#FFF", fontSize:"24px"}} id="text_to_contribute">{contribution.data.text_to_contribute}
                  
                </p>

                
              </div>
            ) : (
              <p className="error-message">Error loading content.</p>
            )}
            {contribution.data.contributionType==="book"?<div>
                
                <div className='book_citation'><p>
              
                    <span className='citation_authors'>{contribution.data.authors.join(', ')} ({contribution.data.date}) {contribution.data.title} 
                        {(contribution.data.edition) || ''}. {contribution.data.publisher}. {contribution.data.doi_or_url}</span>
                    
                    </p></div>
                        <p className="card-id">by: {contribution.id}</p>

                </div>:<></>}
                {contribution.data.contributionType==="newspaper_article"?<div>
                

                <div className='book_citation'><p>
                    <span className='citation_authors'>{contribution.data.authors.join(', ')} ({contribution.data.date}). {contribution.data.title}. 
                      {contribution.data.doi_or_url != ""?contribution.data.doi_or_url:"contribution.data"}</span>
                    
                    </p></div>
                    <p className="card-id">by: {contribution.id}</p>

                </div>:<></>}

                {contribution.data.contributionType==="webpage"?<div>
                

                <div className='book_citation'><p>
                    <span className='citation_authors'>{contribution.data.authors.join(', ')} ({contribution.data.date}). {contribution.data.title}. 
                      {contribution.data.doi_or_url != ""?contribution.data.doi_or_url:"contribution.data"}</span>
                    
                    </p></div>
                    <p className="card-id">by: {contribution.id}</p>

                </div>:<></>}
            </div>


          </div>
        ))
      ) : (
        <p>No contributions available.</p>
      )}
    </div>
  );
};

export default ContributionCards;
