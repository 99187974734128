import "../css/signup.css";
import React, { useEffect, useState } from 'react';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

import { useNavigate } from 'react-router-dom';


interface BlogPostProps {
    app: FirebaseApp
  }

  const SignUp: React.FC<BlogPostProps> = ({  app }) => {
    const auth = getAuth(app);
    const navigate = useNavigate();
const[uid, setUid] = useState("")
  const [signActiveB, setSignActiveB] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailSIn, setEmailSIn] = useState('');
  const [passSIn, setPassSIn] = useState('');
  const [acceptPrivacyB, setAcceptPrivacyB] = useState(false);
  const [acceptTermsB, setAcceptTermsB] = useState(false);
  const [promptBuying, setPromptBuying] = useState('');

  const signUp = () => {
    setSignActiveB(true);
    setEmail("")
    setPassword("")
    setEmailSIn("")
    setPassSIn("")
  };

  const signIn = () => {
    setSignActiveB(false);
    setEmail("")
    setPassword("")
    setEmailSIn("")
    setPassSIn("")
  };

  const createUser = () => {
   
    createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed up 
    const user = userCredential.user;
    navigate('/profile');

    

  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });


  };

  const signUser = () => {
    signInWithEmailAndPassword(auth, emailSIn, passSIn)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    navigate('/profile');

    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });

  };
  useEffect(() => {
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
    // ...
    window.location.replace("/profile/");
  } else {
    // User is signed out
    // ...
  }
});
  },[uid]);
  return (
    <div id="content" className="content">
      <div className="grid-container-1">
        <div id='signUp'>
          <div id="signtabs">
            <ul>
              <li onClick={signUp} className={signActiveB ? 'signbtn signactive' : 'signbtn'}>Sign up</li>
              <li onClick={signIn} className={!signActiveB ? 'signbtn signactive' : 'signbtn'}>Sign in</li>
            </ul>
            <p id='earn'>Learn to Earn Encyclopedia (Earn Solana Quantum Compass Tokens) (Soon)</p>
            <p className='bought'>{promptBuying}</p>
            <h1 className='signactiveTitle'>{signActiveB ? "SIGN UP" : "SIGN IN"}</h1>
            <div className={signActiveB ? 'showsignA' : 'showsignI'}>
              <input value={email} className='inputSign' onChange={e => setEmail(e.target.value)} type='email' placeholder='email'></input>
              <br />
              <input className='inputSign' onChange={e => setPassword(e.target.value)} value={password} id="password1" type='password' placeholder='password'></input>
              <br />
              <input onChange={e => { setAcceptPrivacyB(e.target.checked) }} checked={acceptPrivacyB} className='checkbox_accept' type='checkbox'></input>
              <a className='text_link' href='/privacy-policy/'>Accept Privacy Policy</a>
              <br />
              <input onChange={e => { setAcceptTermsB(e.target.checked) }} checked={acceptTermsB} className='checkbox_accept' type='checkbox'></input>
              <a className='text_link' href='/terms-and-conditions/'>Accept Terms and Conditions</a>
              <br /><br /><br /><br />
              <button className='btnsign' onClick={createUser}>Sign Up</button>
              <br />
            </div>
            <div className={!signActiveB ? 'showsignA' : 'showsignI'}>
              <input className='inputSign' type='email' placeholder='email' onChange={e => setEmailSIn(e.target.value)}></input>
              <br />
              <input onChange={e => setPassSIn(e.target.value)} className='inputSign' id="password2" type='password' placeholder='password'></input>
              <br />
              <button className='btnsign' onClick={signUser}>Sign In</button>
              <p><a className='reset' href='/reset-password/'>Reset Password</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
