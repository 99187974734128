import React, { useState } from 'react';
import '../css/anatomy.css'; // Make sure to import the CSS
import { Timestamp } from 'firebase/firestore';

interface Anatomy {
    anatomy:{ 
    title:string; 
    image:string;
    src:string; 
    author: string; 
    type: string; 
    license: string; 
    license_url: string;}}

  
  
    const AnatomyComponent: React.FC<Anatomy> = ({ anatomy }) => {  const [isModalOpen, setIsModalOpen] = useState(false);

  const openAnatomyModal = () => {
    setIsModalOpen(true);
  };

  const closeAnatomyModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {anatomy && (
        <div>
          <div
            id="openAnatomyModal"
            className="anatomyModal"
            style={{ display: isModalOpen ? 'flex' : 'none' }}
            onClick={closeAnatomyModal}
          >
            <div className="AnatomyModalImageDiv">
              <img  className="AnatomyModalImage" src={anatomy.image} alt="Anatomy" />
            </div>
            <div className="anatomyTitle"> {anatomy.title} | </div>
           
            <a href={anatomy.license_url}>
          
              <div className="anatomyAuthor"> {anatomy.author} | </div>
            </a>
            
            <div className="anatomyLicense"> {anatomy.license} | </div>
          </div>

          <div className="anatomy" onClick={openAnatomyModal}>
            <img  className='image_animal' src={anatomy.image} alt="Anatomy Thumbnail" />
          </div>
        </div>
      )}
    </div>
  );
};

export default AnatomyComponent;
