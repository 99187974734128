import React from "react";
import "../css/AnimalCard.css";

interface AnimalData {
  EndangeredStatus: string;
  Lifespan: string;
  CaptivityLifeSpan: string;
  Weight: string;
  BodyLength: string;
  Tall: string;
  TailLength: string;
}

interface AnimalCardProps {
  animalData: AnimalData;
}

const AnimalCard: React.FC<AnimalCardProps> = ({ animalData }) => {
  return (
    <div className="animal-card">
      <div id="status" className="card-section">
        <h2>Status</h2>
        <p>{animalData.EndangeredStatus}</p>
      </div>
      <div id="lifespan" className="card-section">
        <h2>Lifespan</h2>
        <p>{animalData.Lifespan}</p>
      </div>
      <div id="captivity-lifespan" className="card-section">
        <h2>Captivity Lifespan</h2>
        <p>{animalData.CaptivityLifeSpan}</p>
      </div>
      <div id="weight" className="card-section">
        <h2>Weight</h2>
        <p>{animalData.Weight}</p>
      </div>
      <div id="body-length" className="card-section">
        <h2>Body Length</h2>
        <p>{animalData.BodyLength}</p>
      </div>
      <div id="tall" className="card-section">
        <h2>Height</h2>
        <p>{animalData.Tall}</p>
      </div>
      <div id="tail-length" className="card-section">
        <h2>Tail Length</h2>
        <p>{animalData.TailLength}</p>
      </div>
    </div>
  );
};

export default AnimalCard;
