import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { FirebaseApp } from 'firebase/app';
import '../css/Documentary.css';
import PinterestSaveButton from './PinterestSaveButton';
import VideoGallery from './VideoGallery';
interface AthleteProps {
  firebaseApp: FirebaseApp;
}

interface AthleteData {
  name: string;
  description: string;
  labels: string[];
  category: string;
  youtubeID: string;
  image: string;
  shortDescription: string;
  locomotion: {descriptions:string[],urls:string[];}

}

const Athlete: React.FC<AthleteProps> = ({ firebaseApp }) => {
  const { slug } = useParams<{ slug: string }>();
  const [documentary, setDocumentary] = useState<AthleteData | null>(null);
  const db = getFirestore(firebaseApp);

  useEffect(() => {
    console.log(slug)
    const fetchDocumentary = async () => {
      
      const docRef = doc(db, 'athletes', slug!);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setDocumentary(docSnap.data() as AthleteData);
      } else {
        console.log('No such document!');
      }
    };

    fetchDocumentary();
  }, [firebaseApp, slug]);

  if (!documentary) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="documentary-container">
      <h1 className="title">{documentary.name}</h1>
      <iframe
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${documentary.youtubeID}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video player"
        ></iframe>
      <div
        className="description athleteDescription" style={{color:"black !important", background:"#EEE"}}
        dangerouslySetInnerHTML={{ __html: documentary.description }}
      ></div>

<div className='container_video_image'>
<h1 className='back_title'>{documentary.name} Skills</h1><br></br>
{documentary.locomotion!= undefined && documentary.locomotion!= null?
<VideoGallery urls={documentary.locomotion.urls} descriptions={documentary.locomotion.descriptions} />:
<></>}
</div>

<div className="labels">
        {documentary.labels.map((label, index) => (
          <span key={index} className="label-box">
            {label}
          </span>
        ))}
      </div>
      <div className="category">Category: {documentary.category}</div>
      <div className="video-container">
      <p className='category'>Help us spread love 💗</p> 
      <PinterestSaveButton 
        url={"https://quantumcompass.xyz/athlete/"+slug}
        description={documentary!.shortDescription}
        media={documentary!.image}
        title={documentary!.name}
      />
      <br></br>
    <img src={documentary.image} width="100%"></img>
      </div>
   
    </div>
  );
};

export default Athlete;
