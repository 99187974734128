import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { FirebaseApp } from 'firebase/app';
import '../css/Documentary.css';
import PinterestSaveButton from './PinterestSaveButton';
interface DocumentaryProps {
  firebaseApp: FirebaseApp;
}

interface DocumentaryData {
  title: string;
  description: string;
  labels: string[];
  category: string;
  youtubeID: string;
  image: string;
}

const Documentary: React.FC<DocumentaryProps> = ({ firebaseApp }) => {
  const { slug } = useParams<{ slug: string }>();
  const [documentary, setDocumentary] = useState<DocumentaryData | null>(null);
  const db = getFirestore(firebaseApp);

  useEffect(() => {
    const fetchDocumentary = async () => {
      
      const docRef = doc(db, 'documentaries', slug!);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setDocumentary(docSnap.data() as DocumentaryData);
      } else {
        console.log('No such document!');
      }
    };

    fetchDocumentary();
  }, [firebaseApp, slug]);

  if (!documentary) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="documentary-container">
      <h1 className="title">{documentary.title}</h1>
      <iframe
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${documentary.youtubeID}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video player"
        ></iframe>
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: documentary.description }}
      ></div>

<div className="labels">
        {documentary.labels.map((label, index) => (
          <span key={index} className="label-box">
            {label}
          </span>
        ))}
      </div>
      <div className="category">Category: {documentary.category}</div>
      <div className="video-container">
      <p className='category'>Help us spread love 💗</p><PinterestSaveButton 
        url={"https://quantumcompass.xyz/documentary/"+slug}
        description={documentary!.title}
        media={documentary!.image}
        title={documentary!.title}
      /><br></br>
    <img src={documentary.image} width="100%"></img>
    
      </div>
    </div>
  );
};

export default Documentary;
