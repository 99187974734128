import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import Gerber3DViewer from './Gerber3DViewer';
import ScientificClassificationTable from './ScientificClassificationTable';
import '../css/ScientificClassificationTable.css';
import AnatomyComponent from './Anatomy';
import '../css/article.css';
import QAComponent from './QAComponent';
import allCNFTs from '../allcNFTs.json';
import Cookies from 'js-cookie';
import VideoGallery from './VideoGallery';
import Card from './CardConcept';
import IntroductionCard from './IntroductionCard';
import DistributionComponent from './Distribution';
import AnimalCard from './AnimalCard';
import AddContribution from './AddContribution';
import ContributionCards from './contributionCards';
interface BlogPostProps {
  app: FirebaseApp;
  validCNFTs: number;
  firebaseuser: User | undefined;
}
interface Contribution {
  id: string;
  jsonUrl: string;
  category: string;
}
interface AnimalData {
  EndangeredStatus: string;
  Lifespan: string;
  CaptivityLifeSpan: string;
  Weight: string;
  BodyLength: string;
  Tall: string;
  TailLength: string;
}
interface ScientificClassificationProps {

      Domain: string;
      Kingdom: string;
      Phylum: string;
      Class: string;
      Order: string;
      Suborder: string;
      Superfamily: string;
      Family: string;
      Subfamily: string;
      Genus: string;
      Species: string;

}
interface DistributionData {
  distribution_image: string;
  distribution_image_link: string;
  distribution_html: string;
  distribution: string;
  distribution_citation: string;
  citation: string;
}

interface QuestionsO {
  question: string;
  answer: string;
}

interface PostData {
  title: string;
  image: string;
  description: string;
  author: string;
  date: Timestamp;
  modelUrl: string;
  conservation: string;
  youtubeID: string;
  model3DZip: string;
  scientificClassification: ScientificClassificationProps;
  anatomy: {title:string, image:string, src:string, author: string, type: string, license: string, license_url: string};
  locomotion: {descriptions:string[],urls:string[];}
  introduction: string;

  distribution_image: string;
  distribution_image_link: string;
  distribution_html: string;
  distribution: string;
  distribution_citation: string;
  citation: string;
  SpeciesFacts: AnimalData;
  topQuestions: QuestionsO[]; 
}

const Post: React.FC<BlogPostProps> = ({ app,validCNFTs, firebaseuser }) => {
  const [post, setPost] = useState<PostData | null>(null);
  const [model3DZip, setModel3DZip] = useState("")
  const [loading, setLoading] = useState(true);
  const { slug } = useParams<{ slug: string }>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [zipFilename, setZipFilename] = useState("3D-Quantumcompass.xyz")
  const [classIsDisabled, setClassIsDisabled] = useState("isDisabled");
  const [error, setError] = useState<string | null>(null);
  const [NFTsURL, setNFTsURL] = useState("#");
  const [contributions, setContributions] = useState<Contribution[]>()
  const db = getFirestore(app);
  const auth = getAuth(app);
  interface DatacNFT {
    cNFTs: string[];
  }
  
      const postData = async (url: string, data: PostData): Promise<void> => {
        try {
          const response = await fetch(url, {
            method: 'POST', // Method type
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data) // Convert the TypeScript object into a JSON string
          });
      
          // Check if the request was successful
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          // You can process the response further if needed
          const result = await response.json();
        
          let counterValidI= 0
          const officialAllCNFTs2: DatacNFT = allCNFTs;

          for (var i =0; i<result.length; i++){
            //console.log("cNFTs: "+ result[i].id)
            for (var e=0; e<officialAllCNFTs2.cNFTs.length; e++){
// console.log(officialAllCNFTs[e]+"")
              if(result[i].id==officialAllCNFTs2.cNFTs[e]){
                console.log("valid")
counterValidI+=1;
              }
            }
          }

        
          console.log('Success:', result);
        } catch (error) {
          console.error('Error:', error);
        }
      };




  useEffect(() => {
    const fetchPost = async () => {
      try {
        const docRef = doc(db, 'posts', slug!);
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
          setPost(docSnap.data() as PostData);
          setModel3DZip(docSnap.data().model3DZip)
          setNFTsURL(docSnap.data().NFTsURL)
          setZipFilename(docSnap.data().title+"-3D_QuantumCompass.zip")
          setContributions(docSnap.data().contributions)
          
        } else {
          setError('No such document!');
        }
      } catch (err) {
        setError(`Error getting document: ${err}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
 
    //check if owns
    console.log("validCNFs: "+validCNFTs)
    if (validCNFTs>0){
      setIsDisabled(false)
      setClassIsDisabled("isNotDisabled")
    }


    const checkCookie = () => {
      let currentpathName = window.location.pathname;
      console.log("pathname: "+ currentpathName)
      // /p/1
      let slug= "";
      if (currentpathName.startsWith("/animal/")){
         slug = currentpathName.substring(8, currentpathName.length)
        console.log("slug: "+slug)
   
      }
      const validCNFTs = Cookies.get('validCNFTs_'+slug);
      if (validCNFTs && parseInt(validCNFTs) > 0) {
        setIsDisabled(false);
        setClassIsDisabled("isNotDisabled");
      } else {
        setIsDisabled(true);
        setClassIsDisabled("isDisabled");
      }
    };

    // Check the cookie every 5 seconds
    const interval = setInterval(checkCookie, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);


  }, [slug, db]);

  useEffect(() => {
    const checkAuthState = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsDisabled(false);
          setClassIsDisabled("isNotDisabled");
        } else {
          setIsDisabled(true);
          setClassIsDisabled("isDisabled");
        }
      });
    };

    checkAuthState();
  }, [auth,validCNFTs]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!post) {
    return <div>No post found</div>;
  }

  // Convert Firestore Timestamp to JavaScript Date
  const postDate = post.date.toDate();

  const order = [
    'Domain',
    'Kingdom',
    'Phylum',
    'Class',
    'Order',
    'Suborder',
    'Superfamily',
    'Family',
    'Subfamily',
    'Genus',
    'Species'
];

  async function downloadZipFile(url: string, filename: string): Promise<void> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error downloading file: ${response.statusText}`);
      }
  
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      
      // Cleanup
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error while downloading the ZIP file:', error);
    }
  }
  
  // Usage example
  

  function downloadFile(){
    downloadZipFile(model3DZip, zipFilename);

  }
  


  return (
    <div>
            <div className='container_video_image'>

      <h1 className='back_title'>{post.title}</h1>
      

      <br />
      </div>
      <div className='container_video_image'>
      <img className="image_animal" src={post.image} alt={post.title} />
<br />

     </div> 
   
      <div style={{height:"500px"}} className='container_video_image'>
        <h1 className='back_title'>Documentary</h1><br/>
      <iframe
      style={{height:"400px"}}
    className="video"
    title="Youtube player"
    width="100%"
    height="400px"
    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
    src={`https://youtube.com/embed/${post.youtubeID}?autoplay=0`}
  ></iframe>
  <br></br>


</div>
<div className='container_video_image'>
<h1 className='back_title'>3D Model</h1>
</div>
<div className='container_video_image'>

      <Gerber3DViewer url={post.modelUrl} />
      <br />
      </div>
      <div className='container_video_image'>


      <button onClick={downloadFile} disabled={isDisabled} className={classIsDisabled}>Download 3D Model</button>
      <span className='info'> To download the 3D model <a href='/signup/'>signup</a> or buy an <a href={NFTsURL}>NFT</a></span>
      <br></br>
      <br></br>
</div>

{post.SpeciesFacts!= null && post.SpeciesFacts!= undefined?<div>
  <div className='container_video_image'>
 <h1 className='back_title'>Species Facts</h1><br></br>
</div>
<div className='container_video_image'>
<AnimalCard animalData={post.SpeciesFacts}></AnimalCard></div></div>:<></> }

<div className='container_video_image back_title'>
{post.introduction == null || post.introduction != null?<h1>{post.title}: Core Concepts</h1>:<></>}
</div>

<div className='container_video_image back_title'>
        {post.introduction == null || post.introduction != null?<IntroductionCard introduction={post.introduction}/>:<></>}

</div>
<div className='container_video_image'>

      {post.scientificClassification!= undefined &&post.scientificClassification!= null?<div>
        
        <h1 className='back_title'>Scientific Classification</h1><br></br>
        
        <ScientificClassificationTable classification={post.scientificClassification}></ScientificClassificationTable>
        </div>:<></>}
        </div>
        <div className='container_video_image'>

        {post.anatomy!= undefined &&post.anatomy!= null?<div>
          <h1 className='back_title'>{post.title} Anatomy</h1>
       <AnatomyComponent anatomy={post.anatomy}></AnatomyComponent>
        </div>
        
:<div></div>}
</div>

<div className='container_video_image'>
<h1 className='back_title'>{post.title} Locomotion</h1><br></br>
{post.locomotion!= undefined && post.locomotion!= null?
<VideoGallery urls={post.locomotion.urls} descriptions={post.locomotion.descriptions} />:
<></>}

      
</div>
<div className='container_video_image'>
{post.distribution_html!= undefined && post.distribution_html!= null?
<div id="distribution">
<h1 className='back_title'>{post.title} (Species Distribution)</h1>
<br/>

<DistributionComponent data={post} /></div>:
<></>}
</div>

<div className='container_video_image'>
    {
      post.topQuestions!= null && post.topQuestions != undefined?
      <h1 className='back_title'>Top {post.title} Scientific Questions</h1>:<></>}
</div>
<div className='container_video_image'>
  {post.topQuestions!= null && post.topQuestions != undefined?
  <QAComponent questions={post.topQuestions}></QAComponent>:<></>
}
  </div>
 
    {firebaseuser!= undefined && firebaseuser!= null?
    <div> <div className='container_video_image'>
    <h1 className='back_title'>Community Contributions</h1>
</div>
<div className='container_video_image'>
  {contributions!== undefined?<ContributionCards contributions={contributions} ></ContributionCards> :<></>}

</div>
<div className='container_video_image'>
    <h1 className='back_title'>Add a Contrubution (Earn 10 points when verified)</h1>
</div>
  <div className='container_video_image'>
    <AddContribution  firebaseApp={app} firebaseUser={firebaseuser} firestore={db} slug={slug}></AddContribution>
</div>
</div> :<></>}
  
<div className='container_video_image'>

      <div className='back_title' dangerouslySetInnerHTML={{ __html: post.description }} />
    <br></br>
    <div dangerouslySetInnerHTML={{ __html: post.conservation }} />
<br></br>
      <p className='back_title'>
        <strong className='back_title'>author: {post.author}</strong > - {postDate.toLocaleDateString()}
      </p>
      <br></br>
      <a style={{alignContent:"center"}}  href={"/quiz/"+slug}>
      <button className="cyberpunk-button">
      
      Take Quiz and Earn Tokens
    </button></a>
    </div>
    </div>
    
  );
};

export default Post;
