import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FirebaseApp } from 'firebase/app';
import { getFirestore, doc, getDoc, DocumentData } from 'firebase/firestore';
import '../css/quote.css';
import PinterestSaveButton from './PinterestSaveButton';
interface QuoteProps {
  FirebaseApp: FirebaseApp;
}

interface QuoteData {
  image: string;
  comment: string;
  quote: string;
  author: string;
  category: string;
}

const Quote: React.FC<QuoteProps> = ({ FirebaseApp }) => {
  const { slug } = useParams<{ slug: string }>();
  const [quoteData, setQuoteData] = useState<QuoteData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!slug) {
      setError('No slug provided');
      setLoading(false);
      return;
    }

    const fetchQuote = async () => {
      const db = getFirestore(FirebaseApp);
      const docRef = doc(db, 'quotes', slug);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setQuoteData(docSnap.data() as QuoteData);
        } else {
          setError('No such document!');
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchQuote();
  }, [FirebaseApp, slug]);

  if (loading) return <div className="quote-loading">Loading...</div>;
  if (error) return <div className="quote-error">Error: {error}</div>;

  return (
    <div className="quote-container">
      <img src={quoteData?.image} alt="Quote illustration" className="quote-image" />
      <blockquote className="quote-text">
        "{quoteData?.quote}"
        <footer className="quote-author">- {quoteData?.author}</footer>
      </blockquote>
      <div className="quote-comment">{quoteData?.comment}</div>
      <div className="quote-category">Category: {quoteData?.category}</div>
      <div>
      <h1>Beautiful Pinterest Save Button</h1>
      <PinterestSaveButton 
        url={"https://quantumcompass.xyz/quote/"+slug}
        description={quoteData!.quote}
        media={quoteData!.image}
        title={quoteData!.comment}
      />
    </div>
    </div>
  );
};

export default Quote;
