import "../css/Content.css"
import midjourney from "../img/midjourney.png"
import chatgpt from "../img/chatgpt.jpg"
import chatgpt4 from "../img/chatgpt4.jpg"

function TermsAndConditions() {
    return (<>
<div id='privacy_policy'>
<h1 >Terms and Conditions: </h1>

<p><strong>Terms and Conditions for QuantumCompass.xyz.</strong></p>
<p><em>Last Updated: May 15, 2023</em></p>
<p>Welcome to QuantumCompass.xyz.. By using our website, you are agreeing to comply with and be bound by the following terms and conditions of use. If you disagree with any part of these terms and conditions, please do not use our website.</p>
<p><strong>1. Acceptance of Terms</strong></p>
<p>Your access to and use of QuantumCompass.xyz. ("the Website") is subject exclusively to these Terms and Conditions. You will not use the Website for any purpose that is unlawful or prohibited by these Terms and Conditions.</p>
<p><strong>2. Cookies</strong></p>
<p>Our website uses cookies to provide you with a personalized browsing experience. By using our website, you agree to the use of cookies as outlined in our Privacy Policy.</p>
<p><strong>3. Data Protection</strong></p>
<p>We are committed to ensuring that your privacy is protected. Any information you provide while using this Website will be used in accordance with our Privacy Policy.</p>
<p><strong>4. Display of Ads</strong></p>
<p>By using our website, you consent to the display of advertisements. These ads help us to keep our service free for our users. We strive to display relevant and useful ads based on your browsing history and preferences.</p>
<p><strong>5. User Content</strong></p>
<p>5.1. As a user, you are responsible for the content you upload or post on QuantumCompass.xyz.. You must have the necessary rights and permissions for all content you provide, including text, images, and other types of content.</p>
<p>5.2. By uploading or posting content, you represent and warrant that you own or otherwise control all rights to such content and that use and dissemination of such content by QuantumCompass.xyz. will not infringe or violate the rights of any third party.</p>
<p>5.3. QuantumCompass.xyz. reserves the right to remove any content that we believe is in violation of the above or any other provision of these Terms and Conditions.</p>
<p><strong>6. Premium Products</strong></p>
<p>6.1. QuantumCompass.xyz. offers a Premium products that provides users with additional benefits, including the ability to use AI Prompts.</p>
<p>6.2. We do our best to create great prompts but when going commercially always DYOR. </p>
<p><strong>7. Changes to Terms and Conditions</strong></p>
<p>We reserve the right, at our sole discretion, to change or modify these Terms and Conditions at any time. If we make changes, we will provide notice of such changes, such as by sending an email notification, providing notice through the Services, or updating the "Last Updated" date at the beginning of these Terms and Conditions.</p>
<p>By continuing to access or use our Services, you confirm your acceptance of the revised Terms and Conditions and all of the terms incorporated therein by reference. We encourage you to review the Terms and Conditions frequently to ensure that you understand the terms and conditions that apply when you access or use our Services.</p>
<p><strong>8. Contact Us</strong></p>
<p>If you have any questions about these Terms and Conditions, please contact us at: [serpentnaturae@gmail.com]</p>
</div>
 
        
        
</>  );
}
export default TermsAndConditions;